<template>
  <div id="app" class="app">
    <div class="homeM">
      <!-- 顶部 -->
      <div class="top-banner">
        <!-- 图标 -->
        <img class="logo-img" :src="src1" alt="" />
        <!-- 注册即送 -->
        <!-- <div class="register">
          联系客服领<span class="money">{{ getTokenUnit(registerCash) }}</span
          >token
        </div> -->
        <div class="register">
          联系客服领<span class="money">{{ "500,000" }}</span
          >token
        </div>
        <!-- 红包 -->
        <img
          @click="gohomePage"
          class="img"
          src="/img/home-redpacket.gif"
          alt=""
        />
        <!-- 领取按钮 -->
        <img
          @click="gohomePage"
          class="get_img"
          src="/img/new_mobile14.svg"
          alt=""
        />
      </div>
      <!-- 主体内容 -->
      <div class="title">
        <div class="title_content">
          <img class="img img2" src="/img/new_mobile15.svg" alt="" />
          <img class="img" src="/img/new_mobile16.png" alt="" />
        </div>
      </div>
      <!--福利-->
      <div class="welfare">
        <div class="welfare_content">
          <img class="img" src="/img/new_mobile04.svg" alt="" />
          <img class="img img2" src="/img/new_mobile05.svg" alt="" />
          <img class="img img2" src="/img/new_mobile06.svg" alt="" />
          <img class="img img2" src="/img/new_mobile07.svg" alt="" />
        </div>
      </div>
      <!-- 赠送 -->
      <div class="gift">
        <div class="gift_content">
          <img class="img" src="/img/new_mobile09.svg" alt="" />
          <!-- <div class="tips">
            温馨提示：注册即享<span class="money"
              >{{ getTokenUnit(registerCash) }}token</span
            >，合理规划畅用一月。
          </div> -->
          <div class="tips">
            温馨提示：注册即享<span class="money">{{ "500,000" }}token</span
            >，合理规划畅用一月。
          </div>
          <!-- 占位 -->
          <div class="sate">
            <div class="law">
              <a
                href="https://beian.miit.gov.cn/"
                target="_blank"
                class="foot_fill_text"
                >备案号 : 滇ICP备2024017174号-19</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-footer">
      <div class="left">
        <img class="icon" src="/img/m-gift-pack.gif" alt="" />
        <img class="describe-img" src="/img/describe-banner.svg" alt="" />
      </div>
      <div class="right" @click="gohomePage">
        <img src="/img/new_mobile13.svg" />
      </div>
    </div>
    <div class="mask" v-if="test" @click="cancelMask">
      <img src="/img/mobileopenicon.png" alt="" />
    </div>
  </div>
</template>
<script>
import { getTokenUnit } from "@/utils/util";
import { channelInfo, configInfo } from "@/api/customer";
import { BASE64 } from "../utils/base64.js";
import { downloadUrl } from "@/api/customer";
import { appDownload } from "@/api/download";
import copy2clipboard from "@/utils/copy2clipboard";
import urlUtils from "@/utils/urlUtils";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
const c_swiper = document.getElementsByClassName("swiper");

import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "Mobile",
  components: {
    swiper,
    swiperSlide,
    vueSeamlessScroll,
  },
  data() {
    return {
      src1: "/img/home-logo.png",
      test: false,
      ext: urlUtils.getUrlParamByKey("ext") || "",
      // 标识视频区域是否在可视范围内
      isVisible: false,
      observer: "",
      //代理商id
      cid: "",
      //体验价
      registerCash: "",
      //选中轮播图
      oIndex: 0,
      //轮播图
      swiperList: [],
      swiperOption: {
        // 是否循环
        loop: false,
        // 默认显示几张图片
        slidesPerView: 1,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        autoplay: {
          delay: 5000, //自动切换的时间间隔，单位ms
          stopOnLastSlide: false, //当切换到最后一个slide时停止自动切换
          disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          // 监听slide切换结束后
          //  slideChange:() => {console.log("轮播图滚动事件 --->",c_swiper[0].swiper.activeIndex)}
          slideChange: () => {
            this.oIndex = c_swiper[0].swiper.activeIndex;
          },
        },
      },
    };
  },
  mounted() {
    this.cid = this.$cookies.get("aiseo_channel_ext");
    if (this.cid) {
      //代理商
      this.getChannelInfo();
    } else {
      //系统
      this.getSystemConfigInfo();
    }
    // this.observer = new IntersectionObserver(
    //   (entries, observer) => {
    //     entries.forEach((entry) => {
    //       if (entry.isIntersecting) {
    //         this.isVisible = true;
    //         this.handleLoad();
    //       } else {
    //         this.isVisible = false;
    //         this.handleLoad();
    //       }
    //     });
    //   },
    //   {
    //     rootMargin: "0px",
    //     threshold: 0.8,
    //   }
    // );
    // this.observer.observe(this.$refs.videoMobile);
  },
  methods: {
    getTokenUnit,
    //qq交流群
    goContact() {
      window.open(
        "https://qm.qq.com/cgi-bin/qm/qr?k=hh5ndYUvnv1Tjl4TBUoqx7m4nlUakSr7&jump_from=webapi&authKey=zfoJWpVF8qLP8YybOwHf+Zu5MnM4yGMswcv4YKLIiypIX7mHYOh3Mc4SvsugszJY"
      );
    },
    switchImg(index) {
      //参数index为图片索引
      this.oIndex = index;
      c_swiper[0].swiper.activeIndex = index;
    },
    //代理商信息
    async getChannelInfo() {
      let ret = await channelInfo({
        cid: this.cid,
      }).then(async (res) => {
        if (res.code == 20000) {
          this.channelData = res.data;
          this.systemData = await this.getConfigInfo();
          //代理商注册
          if (this.channelData.balance > this.channelData.register_cash) {
            this.registerCash =
              parseFloat(this.channelData.register_cash) +
              parseFloat(this.systemData.register_cash);
          } else {
            this.registerCash = this.systemData.register_cash;
          }
        }
      });
    },
    //后台配置信息
    async getConfigInfo() {
      let ret = await configInfo().then((res) => {
        if (res.code == 20000) {
          return res.data;
        }
      });
      return ret;
    },
    //系统注册金
    async getSystemConfigInfo() {
      let ret = await configInfo().then((res) => {
        if (res.code == 20000) {
          this.registerCash = parseFloat(res.data.register_cash);
        }
      });
    },
    handleLoad() {
      if (this.isVisible) {
        this.$refs.videoMobile.play();
      } else {
        this.$refs.videoMobile.pause();
      }
    },
    //去掉蒙层
    cancelMask() {
      this.test = false;
    },
    //跳转网页版h5
    gohomePage() {
      if (this.ext.length > 0) {
        window.open("https://www.aiseo.net?ext=" + this.ext);
      } else {
        window.open("https://www.aiseo.net/");
      }
    },
    // 判断是否是微信浏览器
    is_weixin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.app {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
.homeM {
  width: 100vw;
  height: 100%;
  background: #fff;
  //顶部
  .top-banner {
    width: 100vw;
    height: 1.12rem;
    background: rgba(13, 16, 75, 0.5);
    position: fixed;
    top: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.4rem 0 0.4rem;
    .logo-img {
      display: inline-block;
      width: 1.02rem;
      height: auto;
      object-fit: contain;
      margin-right: 0.21rem;
    }
    .register {
      flex: none;
      margin-right: 0.25rem;
      font-family: OPPOSansM;
      font-weight: 400;
      font-size: 0.4rem;
      color: #ffffff;
      .money {
        color: #ff6109;
        font-weight: bold;
      }
    }
    .text-img {
      display: inline-block;
      width: 3.9rem;
      height: auto;
      object-fit: contain;
      margin-right: 0.2rem;
    }
    .img {
      width: 0.64rem;
      height: auto;
      object-fit: contain;
    }
    .get_img {
      width: 2.15rem;
      height: 0.8rem;
      object-fit: cover;
    }
  }
  //主体内容
  .title {
    width: 100%;
    height: auto;
    background: url("/img/new_mobile01.svg") no-repeat 50%;
    background-size: cover;
    padding-bottom: 1.2rem;
    .title_content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1.56rem 0.41rem 0;
      .img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
      .img2 {
        margin-top: 0.6rem;
      }
    }
  }
  // 福利
  .welfare {
    width: 100%;
    height: auto;
    background: #fff;
    background: url("/img/new_mobile03.svg") no-repeat 50%;
    background-size: cover;
    padding: 0 0.41rem 0.8rem;
    .welfare_content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
      .img2 {
        margin-top: 0.6rem;
      }
    }
  }
  //赠送
  .gift {
    width: 100%;
    height: auto;
    background: url("/img/new_mobile08.svg") no-repeat 50%;
    background-size: cover;
    padding-bottom: 1.2rem;
    .gift_content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0.8rem 0.41rem 0;
      position: relative;
      .img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
      .tips {
        position: absolute;
        bottom: 2rem;
        font-size: 0.34rem;
        color: #ffffff;
        .money {
          color: #ffa600;
        }
      }
    }
  }
  //占位
  .sate {
    width: 100%;
    height: 1.6rem;
  }
}
//备案号
.law {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.4rem;
  .foot_fill_text {
    font-family: OPPOSansR;
    font-size: 0.3rem;
    color: #fff;
  }
}
.mobile-footer {
  width: 100%;
  overflow: auto;
  position: fixed;
  bottom: 0;
  // background: url("/img/new_mobile25.svg") no-repeat;
  background: #251ca4;
  background-size: cover;
  opacity: 1;
  z-index: 9999;
  padding: 0.25rem 0.41rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon {
      display: inline-block;
      width: 0.8rem;
      height: auto;
      object-fit: contain;
    }
    .describe-img {
      display: inline-block;
      width: 5.5rem;
      height: auto;
      object-fit: contain;
    }
  }
  .right {
    width: 2.15rem;
    height: 0.8rem;
    display: flex;
  }
}
.mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999;
  text-align: right;
  img {
    width: 3.95rem;
    height: 2.6rem;
    object-fit: cover;
  }
}
</style>
