<template>
  <!-- 广告图 -->
  <div class="banner">
    <div class="new-header" @scroll="handleScroll">
      <div class="header-contain">
        <!-- 发布内容 -->
        <div class="publish">
          <div class="publish_content">
            <img class="publish_img" src="/img/new_home02.svg" alt="" />
          </div>
        </div>
        <div class="model">
          <div class="model_content">
            <img class="img" src="/img/new_home04.svg" alt="" />
            <img class="img img2" src="/img/new_home05.svg" alt="" />
            <img class="img img2" src="/img/new_home06.svg" alt="" />
            <img class="img img2" src="/img/new_home07.svg" alt="" />
          </div>
        </div>
        <!-- 赠送 -->
        <div class="gift">
          <div class="gift_content">
            <img class="img" src="/img/new_home09.svg" alt="" />
            <!-- <div class="tips">
              温馨提示：注册即享<span class="money"
                >{{ getTokenUnit(registerCash) }}token</span
              >，合理规划畅用一月。
            </div> -->
            <div class="tips">
              温馨提示：注册即享<span class="money">{{ "500,000" }}token</span
              >，合理规划畅用一月。
            </div>
            <!-- 备案 -->
            <div class="law">
              <a
                href="https://beian.miit.gov.cn/"
                target="_blank"
                class="foot_fill_text"
                >备案号 : 滇ICP备2024017174号-19</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTokenUnit } from "@/utils/util";
import QRCode from "qrcodejs2";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "HomeBox",
  components: {
    swiper,
    swiperSlide,
    QRCode,
  },
  props: {
    registerCash: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      //浏览器允许自动播放
      isAutoPlay: true,
      // 标识视频区域是否在可视范围内
      isVisible: false,
      observer: "",
    };
  },
  mounted() {
    // this.observer = new IntersectionObserver(
    //   (entries, observer) => {
    //     entries.forEach((entry) => {
    //       if (entry.isIntersecting) {
    //         this.isVisible = true;
    //         this.handleLoad();
    //       } else {
    //         this.isVisible = false;
    //         this.handleLoad();
    //       }
    //     });
    //   },
    //   {
    //     rootMargin: "0px",
    //     threshold: 0.8,
    //   }
    // );
    // this.observer.observe(this.$refs.video);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    getTokenUnit,
    //qq交流群
    goContact() {
      window.open(
        "https://qm.qq.com/cgi-bin/qm/qr?k=hh5ndYUvnv1Tjl4TBUoqx7m4nlUakSr7&jump_from=webapi&authKey=zfoJWpVF8qLP8YybOwHf+Zu5MnM4yGMswcv4YKLIiypIX7mHYOh3Mc4SvsugszJY"
      );
    },
    //移动区域内自动播放
    handleScroll(event) {
      console.log(event);
      // 当滚动条与顶部距离超过100px时，显示按钮
      console.log(event.target.scrollTop);
    },
    handleLoad() {
      if (this.isVisible) {
        this.$refs.video.play();
      } else {
        this.$refs.video.pause();
      }
    },
    //去h5页面
    gohomePage() {
      let ext = this.$cookies.get("aiseo_channel_ext");
      if (ext) {
        window.open("https://www.aiseo.net?ext=" + ext);
      } else {
        window.open("https://www.aiseo.net");
      }
    },
  },
};
</script>
