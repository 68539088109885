<template>
  <div id="app">
    <!-- 首页导航 -->
    <div class="header-box">
      <headerBox :registerCash="registerCash"></headerBox>
    </div>
    <!-- 首页背景加主体 -->
    <div class="home-box">
      <homeBox :registerCash="registerCash"></homeBox>
      <contentBox></contentBox>
    </div>
    <!-- 页脚 -->
    <div class="footer-box">
      <footerBox :ishome="ishome"></footerBox>
    </div>
    <!-- 回到头部小图片 -->
    <!-- <toTop :customer="customer"></toTop> -->
  </div>
</template>

<script>
import headerBox from "../components/Header-box/header-box.vue";
import homeBox from "../components/Home-Box/homebox.vue";
import contentBox from "../components/Home-Box/content.vue";
import footerBox from "../components/Footer-box/footer.vue";
// import toTop from "../components/totop/totop.vue";
import { channelInfo, configInfo } from "@/api/customer";

export default {
  name: "Home",
  data() {
    return {
      //判断是否是首页,改变页脚
      ishome: "",
      customer: "", //客服
      //体验价
      registerCash: "",
    };
  },
  components: {
    headerBox,
    homeBox,
    contentBox,
    footerBox,
  },
  created() {
    if (this.$route.path === "/home") {
      this.ishome = false;
    }
    const id = "pathIdentification";
    localStorage.setItem("path", id);
  },
  mounted() {
    this.cid = this.$cookies.get("aiseo_channel_ext");
    if (this.cid) {
      //代理商
      this.getChannelInfo();
    } else {
      //系统
      this.getSystemConfigInfo();
    }
  },
  methods: {
    //代理商信息
    async getChannelInfo() {
      let ret = await channelInfo({
        cid: this.cid,
      }).then(async (res) => {
        if (res.code == 20000) {
          this.channelData = res.data;
          this.systemData = await this.getConfigInfo();
          //代理商注册
          if (this.channelData.balance > this.channelData.register_cash) {
            this.registerCash =
              parseFloat(this.channelData.register_cash) +
              parseFloat(this.systemData.register_cash);
          } else {
            this.registerCash = this.systemData.register_cash;
          }
        }
      });
    },
    //后台配置信息
    async getConfigInfo() {
      let ret = await configInfo().then((res) => {
        if (res.code == 20000) {
          return res.data;
        }
      });
      return ret;
    },
    //系统注册金
    async getSystemConfigInfo() {
      let ret = await configInfo().then((res) => {
        if (res.code == 20000) {
          this.registerCash = parseFloat(res.data.register_cash);
        }
      });
    },
  },
  destroyed() {
    localStorage.removeItem("path");
  },
};
</script>

<style lang="less" scoped>
#app {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
.home-box {
  background: #fff;
}
</style>
